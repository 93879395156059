<template>
    <Dialog :header="p_dialogHeader" v-model:visible="d_showDialog" :style="{width: p_dialogWidth}" :modal="true" :closable="p_closable">
      <div class="c_info_panel" style="margin-top: 1.5rem; padding-top: 0.5rem; font-size: large">
        <div v-if="p_confirmationMessage">
          <div v-if="p_hasWarning" style="display: grid; grid-template-columns: 1rem auto; grid-gap: 1rem; padding-top: 1rem; padding-bottom: 0.5rem">
              <i class="pi pi-exclamation-circle"></i> {{p_confirmationMessage}}
          </div>
          <div v-else>
            <p>
              {{p_confirmationMessage}}
            </p>
          </div>
        </div>
        <div v-if="p_confirmationMessageArray">
          <div v-if="p_showBullets">
            <ul v-for="confirmationMessage in p_confirmationMessageArray" v-bind:key="confirmationMessage">
              <li>{{confirmationMessage}}</li>
            </ul>
          </div>
          <div v-else>
            <div v-for="confirmationMessage in p_confirmationMessageArray" v-bind:key="confirmationMessage">
              {{confirmationMessage}}<br/>
            </div>
          </div>
        </div>
      </div>

      <div v-if="p_okCallback" :style="{'display': 'grid', 'grid-template-columns': m_getColumns(), 'justify-content': 'center', 'padding-top': '1rem', 'grid-gap': '1rem'}">
        <Button class="g_cs_button_fix p-button-rounded"
                cs_class="confirmation_ok_button"
                @click="p_okCallback">
            OK
        </Button>
      </div>
      <div v-else style="display: grid; grid-template-columns: auto auto; justify-content: center; padding-top: 1rem; grid-gap: 1rem">
        <Button class="g_cs_button_fix p-button-rounded"
                cs_class="confirmation_cancel_button"
                @click="p_cancelCallback">
          {{p_getCancelLabel()}}
        </Button>
        <Button cs_id="submit_dialogue_button" id="submit_confirmation_dialog_id" 
                cs_class="confirmation_submit_button"
                class="g_cs_button_fix p-button-rounded" @click="p_submitCallback" v-if="p_submitCallback ">
          {{p_getSubmitLabel()}}
        </Button>
      </div>
    </Dialog>
</template>

<script>
import Button from "primevue/button";
import Dialog from "primevue/dialog";

/**
 * Used for short informational messages, a set of props are available to customize behavior
 */
export default {
  name: "ConfirmationDialogComponent",
  components: {
    Button,
    Dialog,
  },
  data: function () {
    return {
      d_showDialog: true
    }
  },
  props: {
    p_okCallback: { type: Function, default: null },
    p_okLabel: { type: String, default: "OK" },
    p_hasWarning: { type: Boolean, default: false },
    p_cancelCallback: { type: Function, default: null },
    p_getCancelLabel: { type: Function, default: () => "Cancel" },
    p_getSubmitLabel: { type: Function, default: () => "Submit" },
    p_submitCallback: { type: Function, default: null },
    p_showBullets: { type: Boolean, default: false },
    p_confirmationMessage: { type: String, default: null },
    p_confirmationMessageArray: { type: Object, default: null },
    p_dialogHeader: { type: String, default: null },
    p_showDialog: { type: Boolean, default: null },
    p_dialogWidth: { type: String, default: "42rem" },
    p_closable: { type: Boolean, default: "true" },
  },
  methods: {
    m_getCancelLabel() {
      return this.p_cancelLabel;
      // if (this.p_cancelLabel) {
      //   return this.p_cancelLabel;
      // }
      // else {
      //   return this.p_submitCallback ? "Cancel" : "Close";
      // }
    },
    m_getColumns() {
      if (this.p_submitCallback) {
        return "auto auto";
      }
      else {
        return "auto";
      }
    }
  }
}

</script>

<style>
@import '../css/cs_globals.css';
</style>
<style scoped>
.c_info_panel {
  border-radius: 0.5rem;
  border-style: solid;
  border-width: 0.01rem;
  background-color: #f8fafc;
  border-color: #aaa;
  display: grid;
  padding: 1rem;
}
</style>
