
export const PORTAL_PYTHON_PORT = '5000'
export const PORTAL_PYTHON_URL = process.env.VUE_APP_PORTAL_PYTHON_URL ? process.env.VUE_APP_PORTAL_PYTHON_URL : `http://localhost:${PORTAL_PYTHON_PORT}/api/v1`; 

export const VISUALIZER_PYTHON_PORT = '5005'
export const VISUALIZER_PYTHON_URL = process.env.VUE_APP_VISUALIZER_PYTHON_URL ? process.env.VUE_APP_VISUALIZER_PYTHON_URL: `http://localhost:${VISUALIZER_PYTHON_PORT}`;
// export const VISUALIZER_PYTHON_URL = `http://localhost:${VISUALIZER_PYTHON_PORT}`;

function getAsBoolean(theValue) {

    // console.log("get as boolean, the value", theValue);

    if (typeof theValue === "boolean") {
        return theValue;
    }
    else if (typeof theValue === "string") {
        return theValue.toLocaleLowerCase() === "true";
    }
}

export const PRODUCTION_MODE = process.env.VUE_APP_CAIRN_PRODUCTION_MODE ? getAsBoolean(process.env.VUE_APP_CAIRN_PRODUCTION_MODE) : true;

// console.log("PORTAL_PYTHON_URL", PORTAL_PYTHON_URL);

const padTo2Digits = (num) => {
    return num.toString().padStart(2, '0');
}

export function g_getVisibleFields(unfilteredFields) {
    const visFields = unfilteredFields.filter(fieldObj => {
        const showField = fieldObj.field_visible;
        return showField;
    });

    return visFields;
} 

export function g_getFormGridTemplateColumns(formLabelWidth) {
    return `${formLabelWidth} auto`;
}

export function g_getDateTimeObjFromDateString(dateTimeString) {
    const dateTimeObj = Date.parse(dateTimeString);
    return dateTimeObj;
}

export function g_getDateObjFromDateString(dateString) {
    // console.log("dateString", dateString);
    try {
        const parts = dateString.split('-');
        const dateObj = new Date(parts[0], parts[1] - 1, parts[2]);

        if (dateObj) {
            if (dateObj.toString() === "Invalid Date") {
                return null;
            }
        }
        else {
                return null;
        }
        // console.log(":: X dateObj", dateObj);
        return dateObj;
    }
    catch (e) {
        console.error(":: X error", e);
        return null;
    }
}

export function g_getDefaultDateOfBirth() {
    return new Date(1990, 0, 1);
}

export function g_formatDateObjAsString(inputDateObj) {
    const preMonth = inputDateObj.getMonth();
    const month = padTo2Digits(preMonth + 1);
    const preDay = inputDateObj.getDate();
    const day = padTo2Digits(preDay);
    const fullYear = inputDateObj.getFullYear();

    const formattedDateString = `${fullYear}-${month}-${day}`;
    // console.log("g_formatDateObjAsString, date info", {inputDateObj, preMonth, month, preDay, day, fullYear, formattedDateString})
    return formattedDateString;
}

export function g_objectArrayToString(inputArray) {
    let objArray = []
    inputArray.forEach(item => objArray.push(g_objectToString(item)))
    return objArray
}

export function g_consoleLogVue(msg, obj) {
    if (obj) {
        //console.log(msg, JSON.parse(JSON.stringify(obj, null, 1)))
        console.log(msg, JSON.stringify(obj, null, 3))
    }
    else {
        // console.log(`${msg}: (object is null)`)
    }
}

export function g_roleSetContainsDesiredRoles(roleSet, desiredRoles) {
    if (roleSet.some((role) => desiredRoles.includes(role))) {
        return true
    }
    else {
        return false
    }
}

export function g_objectToString(obj) {
    let stringifiedVersionArray = []
    let cntEntry = -1

    for (const [key, value] of Object.entries(obj)) {
        cntEntry++
        // const entry = `${cntEntry}: ${key}: ${value}`
        const entry = `${value}`
        if (cntEntry === 0) {
            continue
        }
        if (cntEntry > 3) {
            break
        }
        stringifiedVersionArray.push(entry)
    }

    const entriesString = stringifiedVersionArray.join(',')
    const stringifiedVersion = `${entriesString}`
    return stringifiedVersion
}

export function g_convertFieldArrayToObject(fields) {
    let newObj = {};

    fields.forEach(item => {
        const fieldKey = item.field_key;
        const fieldValue = item.field_value;
        newObj[fieldKey] = fieldValue;
    });

    return newObj;
}

export function g_convertObjectToFieldArray(inputObj) {
    let populatedFieldArray = [];

    Object.keys(inputObj).map((addressKey, cnt) => {
        const addressFieldValue = inputObj[addressKey];

        if (addressFieldValue !== null) {
            const populatedFieldEntry = { field_id: cnt, field_key: addressKey, field_value: addressFieldValue };
            populatedFieldArray.push(populatedFieldEntry);
        }
    });

    return populatedFieldArray;
}

export function g_toTitleCase(str) {
    str = str.replaceAll('_', ' ')
    return str.toLowerCase().split(' ').map(function(word) {
        // TODO: we should get a user-friendly label along with the constants
        // a bit of a hack to deal with turning a resource from
        // something like VISUALIZER_UI_LINK to Visualizer UI Link
        // or AUDIT_LOG to Audit Log
        // if (word.length < 4 && word !== 'log') {
        // console.log('word', word)
        if (word === 'phi') {
            return 'PHI'
        }
        else if (word === 'ui') {
            return 'UI'
        }
        else if (word === 'dicom') {
            return 'DICOM'
        }
        else if (word === 'bcl') {
            return 'BCL'
        }
        else {
            return word.replace(word[0], word[0].toUpperCase())
        }
    }).join(' ');
}
