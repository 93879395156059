import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/HomeView.vue'

const routes = [
  {
    path: '/audit',
    name: 'AuditView',
    component: () => import(/* webpackChunkName: "AuditView" */ '../views/AuditView.vue')
  },
  {
    path: '/create_order',
    name: 'CreateOrder',
    component: () => import(/* webpackChunkName: "CreateOrderView" */ '../views/CreateOrderView.vue')
  },
  {
    path: '/hospitals',
    name: 'CreateSite',
    component: () => import(/* webpackChunkName: "SiteView" */ '../views/SiteView.vue')
  },
  {
    path: '/users',
    name: 'CreateUser',
    component: () => import(/* webpackChunkName: "UserView" */ '../views/UserView.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "LoginView" */ '../views/LoginView.vue')
  },
  {
    path: '/last_order',
    // name: 'Home',
    // GOTO_ORDERS_ISSUE WAS redirect: '/orders',
    redirect: '/orders',
    // component: Home
  },
  {
    path: '/orders',
    name: 'Orders',
    component: () => import(/* webpackChunkName: "OrderTableView" */ '../views/OrderTableView.vue')
  },
  {
    path: '/',
    name: 'Home',
    // GOTO_ORDERS_ISSUE WAS redirect: '/orders',
    redirect: '/login',
    component: Home
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/orders',
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
