import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

const vuexStore = new Vuex.Store({
  plugins: [createPersistedState({
      storage: window.sessionStorage,
    })],
    state: {
      s_userName: null,
      s_locale: 'en',
      s_orderId: null,
      s_sessionExpired: false
    },
    mutations: {
      changeGlobalStateUserName(state, userName) {
        state.s_userName = userName; 
        // commented out these two lines due to flashing
        // window.location.reload();
        // window.location.href = '/orders';
      },
      changeGlobalStateLocale(state, locale) {
        state.s_locale = locale;
      },
      changeGlobalStateCurrentOrderId(state, orderId) {
        state.s_orderId = orderId;
      },
      changeGlobalStateSessionExpired(state, sessionExpired) {
        state.s_sessionExpired = sessionExpired;
      }
    },
    getters: {
       userName(state, getters) {
         return getters.s_userName;
       },
       sessionExpired(state, getters) {
         return getters.s_sessionExpired;
       },
       orderId(state, getters) {
         return getters.s_orderId;
       }
    }
  })

  export default vuexStore