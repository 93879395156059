import axios from 'axios';
import vuexStore from '../store.js'
import qs from 'qs';

import {g_consoleLogVue, PORTAL_PYTHON_URL} from './cs_globals.js';
import {CsRestMethod} from "@/utils/cs_constants";

const CHANGE_PASSWORD_ENDPOINT = "change_password";
const VALIDATE_TOKEN_ENDPOINT = "validate_token";
const CONFIRM_MFA_OTP_ENDPOINT = "confirm_mfa_otp_value";
import router from "@/router";


export default async function g_callPythonApi(endpoint, inputParameters=null, pythonUrl=PORTAL_PYTHON_URL, method=CsRestMethod.GET) {
    const args = { endpoint: endpoint, inputParameters: inputParameters, pythonUrl: pythonUrl, method: method}
    return g_callPythonApiWithArgs(args)
}

export const isDaylightSavings = () => {
    const d = new Date();
    const jan = new Date(d.getFullYear(), 0, 1).getTimezoneOffset();
    const jul = new Date(d.getFullYear(), 6, 1).getTimezoneOffset();
    return Math.max(jan, jul) !== d.getTimezoneOffset();    
}

// function getCookie(cname) {
//     var name = cname + "=";
//     var decodedCookie = decodeURIComponent(document.cookie);
//     var ca = decodedCookie.split(';');
//     console.log("::: decoded cookie", {decodedCookie});
//     for (var i = 0; i < ca.length; i++) {
//         var c = ca[i];
//         while (c.charAt(0) == ' ') {
//             c = c.substring(1);
//         }
//         if (c.indexOf(name) == 0) {
//             return c.substring(name.length, c.length);
//         }
//     }
//     return null;
// }

export const g_callPythonApiWithArgs = async (args) => {

    const locale = vuexStore.state.s_locale;

    // axios.defaults.xsrfHeaderName = 'X-CSRF-TOKEN'

    axios.defaults.headers.common['Accept-Language'] = locale; // for all requests
    axios.defaults.headers.common['X-Cairn-Locale-Setting'] = locale; // for all requests
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    axios.defaults.headers.common['X-Cairn-Time-Zone'] = timeZone; // for all requests
    const utcOffset = -1*(new Date()).getTimezoneOffset()/60;
    axios.defaults.headers.common['X-Cairn-UTC-Offset'] = utcOffset; // for all requests
    const isDaylightSavingsValue = isDaylightSavings();
    axios.defaults.headers.common['X-Cairn-Is-Daylight-Savings'] = isDaylightSavingsValue; // for all requests
    // const csrfToken = document.querySelector('meta[name="csrf-token"]').content;
    // let token = document.head.querySelector('meta[name="csrf-token"]');

    // const csrfToken = getCookie("CSRF-TOKEN");
    // csrfToken && console.log(":::: csrfToken", csrfToken);


    if (!args.pythonUrl) {
        args.pythonUrl = PORTAL_PYTHON_URL;
    } 
    if (!args.method) {
        args.method = CsRestMethod.GET;
    }            // headerObj = {
        //     "Content-Type": contentType,
        //     "credentials": "include"
        // }; 

    // console.log('in call args', args)
    let response = null

    try {
        if (args.method === CsRestMethod.GET) {

            const headers = {
                headers: { 'credentials': 'include', }
                // 'Accept-Language': locale }
            }
            const fullPythonUrl = `${args.pythonUrl}/${args.endpoint}`;
            const inputParams = args.inputParameters;
            // console.log("BEFORE. in call python. url", fullPythonUrl);
            // console.log("BEFORE. in call python. inputParams", inputParams);
            response = await axios.get(fullPythonUrl, inputParams, headers)
            // g_consoleLogVue(":: get response", response);
            // console.log("AFTER in call python. response", response);
        }
        else if (args.method === 'POST') {
            const formData = new FormData()

            const formParams = args.inputParameters.params
            // console.log('formParams', formParams)
            for (const key in formParams) {
                const value = formParams[key]
                formData.append(key, value)
                // formData.append(key, value)
            }

            const extraParams = formParams.extra_params;
            // console.log("extra params", extraParams);
            const extraParamsString = qs.stringify(extraParams);
            formParams.extra_params = extraParamsString;

            const contentType = "multipart/form-data";

            // headerObj = {
            //     "Content-Type": contentType,
            //     "credentials": "include"
            // };

            // if (csrfToken) {
            //     headerObj["X-CSRF-TOKEN"] = csrfToken;
            // }

            const headers = {
                headers: { "Content-Type": contentType,
                            "credentials": "include",
                }
            };
            
            response = await axios.post(`${args.pythonUrl}/${args.endpoint}`, formData, headers)
            // g_consoleLogVue(":: post response", response);
        }
    }
    catch (ex) {

        if (ex) {
            const exResponse = ex.response ? ex.response : null;
            const exResponseStatus = exResponse ? exResponse.status : null;
            if (exResponseStatus === 401) {
                const includeRestCallToLogout = false;
                await g_logoutSession(includeRestCallToLogout);
                return [null, "Session Expired"];
            }
        }

        const errorMsg = `${ex.message}`
        // console.log(`AFTER EXCEPTION in call python. ex [${errorMsg}]`, ex);

        if (ex.isAxiosError && ex.name === 'Error' && errorMsg === 'Network Error') {
            const msg = 'Cannot connect to web server'
            return [ null, msg ]
        }
        else {
            const msg = `Error retrieving data: ${ex.message}`
            return [ null, msg ]
        }
    }

    const key = "x-cairn-expired";
    const responseHeader = response.headers;
    const xCairnExpired = responseHeader[key];
    // xCairnExpired && console.log("header expired", xCairnExpired);

    if (xCairnExpired) {
        await g_logoutSession();
        return [null, "Session Expired"];
    }
    return [response, null]
}

export async function g_formatAddress(address, includeAddressDescription=false) {
    let params = { address_obj: address, include_address_description: includeAddressDescription };
    const inputParameters = {params: params };
    const endpoint = "get_formatted_address_array";
    const [response, errorMsg] = await g_callPythonApi(endpoint, inputParameters);

    if (g_responseHasError(response, errorMsg)) {
        return ["ADDRESS FORMATTING FAILED"];
    }
    const addressInfoArray = response.data;
    return addressInfoArray;
}

export function g_showErrorToast(msg, thisObj, life=3000) {
    // console.log("show error", );
    thisObj.$toast.add({severity:'error', summary: 'Error', detail:msg, life: life, site: 'main'})
}

export function g_showInfoToast(msg, thisObj, timeout=6000) {
    thisObj.$toast.add({
        severity: 'info',
        summary: '',
        detail: `${msg}`,
        life: timeout,
        site: 'main',
        closable: true
    })
}

export function g_showSuccessToast(msg, thisObj, timeout=6000) { //6000
    thisObj.$toast.add({severity:'success', summary: '', detail:`${msg}`, life: timeout, site: 'main', closable: true})
}

export const g_getVisualizerManifestUrl = async () => {
    const visualizer_manifest_endpoint = "visualizer_manifest";
    const inputParameters = {};

    let [response, errorMsg] = await g_callPythonApi(visualizer_manifest_endpoint);
    return response;
}

const LOGOUT_ENDPOINT = 'logout'

export async function g_logoutSession(includeRestCallToLogout=true) {
    // console.log("in logout");

    vuexStore.commit('changeGlobalStateUserName', null);
    vuexStore.commit('changeGlobalStateLocale', null);
    vuexStore.commit('changeGlobalStateCurrentOrderId', null);

    if (includeRestCallToLogout) {
        let [response, errorMsg] = await g_callPythonApi(LOGOUT_ENDPOINT)
    }

    await router.push("/")

    vuexStore.commit('changeGlobalStateSessionExpired', true);
    window.location.href = '/';
}


export function g_getErrorMsg(response, errorMsg) {
    if (errorMsg) {
        return errorMsg;
    }
    else {
        if (!response || !response.data) {
            return "webserver not available";
        }
        errorMsg = response.data.error ? response.data.error : "problem retrieving information"
        return errorMsg;
    }
}

export function g_responseHasError(response, errorMsg=null) {

    if (errorMsg) {
        return true;
    }

    if (!response) {
        return true;
    }
    else {
        if (response.hasOwnProperty('data')) {
            if (response.data) {
                const hasError = response.data.error ? true : false;
                return hasError;
            }
            else {
                return false;
            }
        }
        else {
            return true;
        }
    }
}

export async function g_retrieveTableDataAndColumns(endpoint, showAllDeleted, thisObj, orderId=null, siteId=null) {
    let params = { show_deleted: showAllDeleted }

    if (orderId) {
        params.order_id = orderId;
    }

    if (siteId) {
        params.site_id = siteId;
    }
    const inputParameters = {params: params }
    const [response, errorMsg] = await g_callPythonApi(endpoint, inputParameters)

    let tableInfo = {}

    if (g_responseHasError(response, errorMsg)) {
        errorMsg = g_getErrorMsg(response, errorMsg)
        g_showErrorToast(errorMsg, thisObj)
        return [null, null]
    }

    let tableData = []
    let serverFields = response.data.columns
    // console.log('serverFields', serverFields)

    // response.data.table.forEach((item, id) => {
    //     tableData.push(item)
    // })

    tableInfo.table = response.data.table
    tableInfo.extra = response.data.extra
    tableInfo.columns = serverFields

    const clientFields = g_getClientFieldsFromServerFields(serverFields)
    const return_value = [tableInfo, clientFields]
    return return_value
}

export function g_getClientFieldsFromServerFields(serverFields, fromForm=false) {

    // console.log('serverFields', serverFields)

    let clientFields = []

    serverFields.forEach( (item, id) => {
        let clientValue = ''

        let fieldType = 'text'

        // a little confusn
        const formHidden = item.form_hidden !== null && item.form_hidden !== undefined ? item.form_hidden : false;

        if (!formHidden) {
            let clientField = { 
                field_id: id, 
                field_label: item.column_label, 
                field_key: item.column_name, 
                field_value: clientValue, 
                field_type: fieldType, 
                field_visible: item.client_visible,
                field_editable: item.column_editable,
                field_mask: item.column_mask
            }

            if (item.special_column !== null && item.special_columm !== undefined) {
                clientField.special_column = item.special_column;
            }
            // console.log('clientField', clientField)
            clientFields.push(clientField)
        }
    })

    return clientFields
}

const GET_USER_PREFERENCE_ENDPOINT = "user_preference"

export async function g_retrieveUserPref(preferenceKey) {
    const params = { preference_key: preferenceKey};
    // console.log("params", params);
    const inputParameters = { params: params };
    let [response, errorMsg] = await g_callPythonApi(GET_USER_PREFERENCE_ENDPOINT, inputParameters);

    if (g_responseHasError(response, errorMsg)) {
        errorMsg = g_getErrorMsg(response, errorMsg)
        g_showErrorToast(errorMsg, this);
        return
    }

    const data = response.data;
    let preferenceValue = null;

    if (preferenceKey in data) {
        preferenceValue = data[preferenceKey];
    }

    return preferenceValue;
}

const PERSIST_USER_PREFERENCE_ENDPOINT = "persist_user_preference"

export async function g_persistUserPref(preferenceKey, preferenceValue) {
    const params = { preference_key: preferenceKey, preference_value: preferenceValue };
    const inputParameters = { params: params };
    await g_callPythonApi(PERSIST_USER_PREFERENCE_ENDPOINT, inputParameters);
}

export async function g_retrieveFakeData(endpoint, fields, thisObj, inputParameters=null) {
    // g_consoleLogVue("in retrieve fake data, input params", inputParameters);
    let [response, errorMsg] = await g_callPythonApi(endpoint, inputParameters)

    if (g_responseHasError(response, errorMsg)) {
        errorMsg = g_getErrorMsg(response, errorMsg)
        g_showErrorToast(errorMsg, thisObj)
        return [];
    }

    const newClientFields = g_getClientFieldsFromClientFieldsAndRow(fields, response.data)
    return newClientFields
}

export function g_getClientFieldsFromClientFieldsAndRow(fields, row) {
    let newClientFields = []

    fields.forEach( (item, id) => {
        let newValue = row ? row[item.field_key] : null;

        if (newValue === undefined) {
            newValue = null
        }

        let newClientField = {...item, field_value: newValue};

        newClientFields.push(newClientField)
    })

    return newClientFields
}

export async function g_submitForm(endpoint, fields, thisObj=null, extraParams=null, method=CsRestMethod.POST) {

    let params = {}

    fields.forEach(item => {
        params[[item.field_key]] = item.field_value
    })

    if (extraParams) {
        params.extra_params = extraParams;
        // g_consoleLogVue("params after extra_params", params);
    }

    let inputParameters = {params: params }

    // g_consoleLogVue("submit form, params", inputParameters)

    let [response, errorMsg] = await g_callPythonApi(endpoint, inputParameters, PORTAL_PYTHON_URL, method);

    if (g_responseHasError(response, errorMsg)) {
        errorMsg = g_getErrorMsg(response, errorMsg);
    }

    return [response, errorMsg]
}

export async function g_submitMfaOtp(userName, userPassword, mfaOtpValue) {
    const inputParameters = { params: { user_name: userName, user_password: userPassword, mfa_otp_value: mfaOtpValue} };
    const theArgsInput = { endpoint: CONFIRM_MFA_OTP_ENDPOINT, inputParameters: inputParameters, method: CsRestMethod.POST };

    let [response, errorMsg] = await g_callPythonApiWithArgs(theArgsInput);

    if (g_responseHasError(response, errorMsg)) {
        errorMsg = g_getErrorMsg(response, errorMsg);
    }

    return [response, errorMsg]
}

export async function g_changePassword(userName, oldPassword, newPassword, forcePasswordChange = null) {

    const inputParameters = { params: { user_name: userName, old_password: oldPassword, new_password: newPassword, force_password_change: forcePasswordChange } };
    const theArgsInput = { endpoint: CHANGE_PASSWORD_ENDPOINT, inputParameters: inputParameters, method: CsRestMethod.POST };

    let [response, errorMsg] = await g_callPythonApiWithArgs(theArgsInput);

    let success = false;

    if (g_responseHasError(response, errorMsg)) {
        success = false;
    }
    else {
        success = true;
    }

    return success;
}
