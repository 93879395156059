<template>
    <div>
      <div>
        <ProgressSpinner :class="d_loadingClass"></ProgressSpinner>
      </div>
      <Dialog header="Configure Two-Factor Authentication" v-model:visible="d_showOTPDialog" :style="{width: '52rem' }" :modal="true">
        <div class="c_info_panel" style="margin-top: 1.0rem; margin-bottom: 0.5rem; padding-top: 0.0rem; font-size: large">
          <div style="margin: 0.5rem; padding-top: 1rem">
            If you have previously configured an authenticator TOTP device, click Done
          </div>
          <div style="margin: 0.5rem; padding-top: 1rem">
            To set up two-factor authentication, enter your password and click Get Setup Key
          </div>
          <!-- <div style="height: 23.5rem; display: grid; grid-template-columns: auto; justify-self: center; align-self: center; padding-top: 0.0rem; grid-gap: 0.0rem"> -->
          <div style="height: 25.5rem; padding-top: 0.5rem;">
            <!-- <div style="display: grid; align-items: center; grid-template-rows: 5rem; justify-content: center; grid-gap: 0.5rem"> -->
            <div style="display: flex; justify-content: center">
                <!-- <div style="justify-self: center">Please enter your password and press submit</div> -->
                <div id="otp_entry_id">
                  <Password style="justify-self: center; width: 35rem" 
                            id="g_cs_password_id"
                            v-model="d_passwordForMfaOtp" 
                            autocomplete="off"
                            toggleMask
                            :feedback="false" />
                </div>
            </div>
            <div v-if="!d_requestedOTPCompleted" style="margin-left: 0.5rem; margin-right: 0.5rem; margin-top: 0.0rem">
              <!-- <div> Please click the button below to have a One Time Password sent to your phone.  </div> -->

              <div style="display: flex; align-items: center; justify-content: center; padding-top: 1.5rem">
                <Button id="request_password_button_id" class="g_cs_button_fix p-button-rounded" @click="m_requestOTPButtonCallback">Get Setup Key</Button>
              </div>

              <div style="padding-top: 1rem; display: flex; justify-content: center" v-if="d_mfaOtpSetupKey">
                Setup Key
              </div>
              <div style="padding-top: 0.5rem; display: flex; justify-content: center" v-if="d_mfaOtpSetupKey">
                {{d_mfaOtpSetupKey}}
              </div>
              <!-- <div  style="display: grid; grid-template-columns: auto; justify-content: center" > -->
              <div style="display: flex; justify-content: center">
                <img v-if="d_mfaOtpImageData" :style="{width: '15rem'}" :src="d_mfaOtpImageData"/>
              </div>

            </div>
          </div>
        </div>

        <div style="display: grid; grid-template-columns: auto auto; justify-content: center; padding-top: 1rem; grid-gap: 1rem">
          <Button id="cancel_notification_settings_button_id"
                  class="g_cs_button_fix p-button-rounded"
                  @click="m_cancelOTPDialogCallback">
            Done
          </Button>
          <!-- <Button 
                  id="submit_notification_settings_button_id"
                  class="g_cs_button_fix p-button-rounded"
                  @click="m_submitOTPDialogCallback">
            Submit
          </Button> -->
        </div>
      </Dialog>
          <div style="display: grid; grid-template-rows: auto auto; justify-content: center; padding-top: 1rem; grid-gap: 1rem">
            <div style="display: grid; grid-template-rows: auto auto; justify-content: start; padding-right: 5rem; padding-top: 1rem; grid-gap: 1rem">
              <div style="display: grid; width: 40rem; grid-template-columns: auto auto; grid-gap: 0.5rem; justify-content: end; align-items: center;">
                By clicking this button, you enable two-factor authentication via authenticator app
                <InputSwitch id="opt_in_mfa_otp" style="padding-left: 0.5rem" class="c_input_switch" v-model="d_optInToMfaOtp" @change="m_optInMfaOtpCallback" />
              </div>
              <div style="padding-top: 2.0rem; display: grid; width: 40rem; grid-template-columns: auto auto; grid-gap: 0.5rem; justify-content: end; align-items: center;">
                By clicking this button, you authorize Cairn to send you text notifications
                <InputSwitch id="enable_text_notifications_switch_id" style="padding-left: 0.5rem" class="c_input_switch" v-model="d_optInToSmsNotifications" @change="m_optInSmsNotificationCallback" />
              </div>
              <div style="display: grid; width: 40rem; grid-template-columns: auto auto; grid-gap: 0.5rem; justify-content: end; align-items: center;">
                By clicking this button, you authorize Cairn to send you email notifications
                <InputSwitch id="enable_email_notifications_switch_id" style="padding-left: 0.5rem" class="c_input_switch" v-model="d_optInToEmailNotifications" @change="m_optInEmailNotificationCallback" />
              </div>
            </div>
        </div>
        <DataTable style="padding-top: 0.0rem; width: 100%; height: 100%"
                   stripedRows
                   v-model:filters="d_filters"
                   :globalFilterFields="['preference_label']"
                   :value="d_data"
                   :paginator="false"
                   :rowHover="true">

          <template #header>
            <div id="settings_header_id" style="display: grid; grid-template-columns: auto auto; justify-content: center; padding-top: 0rem; padding-bottom: 1rem; gap: 0rem;">
              <span style="justify-self: end;" class="p-input-icon-right">
                  <Button id="my_contact_info" label="My Contact Info" class="g_cs_button_fix p-button-rounded" @click="m_toggleContactDialog" />
                  <!-- <ConfirmationDialogComponent v-if="d_showMfaOtpInfo"
                    :p_confirmationMessageArray="d_showMfaOtpInfo"
                    p_dialogWidth="48rem"
                    p_dialogHeader="Authenticator Information"
                    :p_cancelCallback="m_toggleMfaOtpDialog"
                  /> -->
                  <ConfirmationDialogComponent v-if="d_showUserContactDialog"
                    :p_confirmationMessageArray="d_userContactInfo"
                    p_dialogWidth="48rem"
                    p_dialogHeader="My Contact Information"
                    :p_getCancelLabel="m_getCancelLabel"
                    :p_cancelCallback="m_toggleContactDialog"
                  />
                </span>

            </div>
          </template>
          <Column v-for="col of d_tableColumns"
                  :field="col.preference_key"
                  :header="col.header"
                  :sortable="false"
                  :key="col.preference_key">
            <template #body="slotProps">
              <div v-if="col.field === 'preference_label'">
                {{slotProps.data.preference_label}}
              </div>
              <div v-else-if="col.field === 'sms_preference_value'">
                <InputSwitch
                            :id="slotProps.data.preference_key + '_EMAIL'"
                            :disabled="!d_optInToSmsNotifications || d_disableSnsNotifications"
                             class="c_input_switch"
                             v-model="d_enableSmsNotificationDict[slotProps.data.preference_key]"
                />
              </div>
              <div v-else-if="col.field === 'email_preference_value'">
                <InputSwitch 
                            :id="slotProps.data.preference_key + '_PHONE'"
                            :disabled="!d_optInToEmailNotifications"
                             class="c_input_switch"
                             v-model="d_enableEmailNotificationDict[slotProps.data.preference_key]"
                />
              </div>
            </template>
          </Column>
        </DataTable>
        <div style="display: grid; grid-template-columns: auto auto; justify-content: center; padding-top: 1rem; grid-gap: 1rem">
          <Button 
                  id="cancel_notification_settings_button_id"
                  class="g_cs_button_fix p-button-rounded"
                  @click="p_cancelCallback">
            Cancel
          </Button>
          <Button id="submit_notification_settings_button_id" class="g_cs_button_fix p-button-rounded" @click="p_submitCallback">
            Submit
          </Button>
      </div>
    </div>
</template>

<script>
import { PORTAL_PYTHON_URL } from '../utils/cs_globals.js'
import g_callPythonApi, {
  g_responseHasError,
  g_getErrorMsg,
  g_callPythonApiWithArgs,
  g_showErrorToast,
  g_showInfoToast, g_showSuccessToast
} from '../utils/rest_manager.js'

import {CsNotificationMechanism, CsPreferenceKey} from '../utils/cs_constants.js';
import {CsRestMethod } from '../utils/cs_constants.js'


import DataTable from 'primevue/datatable'
import InputText from "primevue/inputtext";
import Column from 'primevue/column';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import MultiSelect from 'primevue/multiselect';
import Password from 'primevue/password';
import Dropdown from 'primevue/dropdown';
import Checkbox from 'primevue/checkbox';
import {FilterMatchMode} from "primevue/api";
import InputSwitch from 'primevue/inputswitch';
import ProgressSpinner from "primevue/progressspinner";
import ConfirmationDialogComponent from "@/components/ConfirmationDialogComponent";


const NOTIFICATION_SETTINGS_ENDPOINT = "notification_settings"
const SET_NOTIFICATION_SETTINGS_ENDPOINT = "set_notification_settings"
const INITIATE_MFA_OTP_ENDPOINT = "initiate_mfa_otp"
const CONFIRM_SNS_OTP_VALUE_ENDPOINT = "confirm_sns_otp_value"

/**
 * Manages user settings for notification and MFA. Can be expanded to include more settings.
 * @component
 */
export default {
  name: "NotificationSettingsComponent",
  components: {
    ProgressSpinner,
    DataTable,
    InputText,
    Column,
    Button,
    InputSwitch,
    Checkbox,
    Dialog,
    Dropdown,
    Password,
    MultiSelect,
    ConfirmationDialogComponent
},
  data: function() {
    return {
      d_filterFields: [],
      d_filterPlaceholder: "Filter",
      d_filters: {
        'global': {
          value: '',
          matchMode: FilterMatchMode.CONTAINS
        }
      },
      d_data: [],
      d_tableColumns: [ {field: "preference_label", header: "Notification Type"}, {field: "sms_preference_value", header: "Text"}, {field: "email_preference_value", header: "Email"}],
      d_enableSmsNotificationDict: {},
      d_enableEmailNotificationDict: {},
      d_optInToSmsNotifications: false,
      d_optInToEmailNotifications: false,
      d_optInToMfaOtp: false,
      d_disableSnsNotifications: true,
      d_loadingClass: "g_c_not_loading",
      d_showOTPDialog: false,
      d_passwordForMfaOtp: null,
      d_snsOtpConfirmed: false,
      d_requestedOTPCompleted: false,
      d_snsOtpValue: null,
      d_userContactInfo: [],
      d_showUserContactDialog: false,
      d_mfaOtpSetupKey: null,
      d_mfaOtpImageData: null,
    }
  },
  directives: {
    focus: {
      inserted: function (el) {
        el.focus()
      }
    }
  },
  props:  {
    p_preSaveNotificationSettingsCallback: { type: Function, default: null },
    p_closeNotificationDialog: { type: Function, default: null },
    p_isSaving: { type: Boolean, default: false }
  },
  async mounted() {
    let params = {};
    let inputParameters = {params: params};
    let [response, errorMsg] = await g_callPythonApi(NOTIFICATION_SETTINGS_ENDPOINT, inputParameters);

    if (g_responseHasError(response, errorMsg)) {
      errorMsg = g_getErrorMsg(response, errorMsg)
      g_showErrorToast(errorMsg, this);
      console.error('error');
      return;
    }

    let enableSmsNotificationDict = {};
    let enableEmailNotificationDict = {};

    let newData = [];
    const responseData = response.data;
    this.d_userContactInfo.push("");
    this.d_userContactInfo.push(`My email: ${responseData.user_email}`);
    this.d_userContactInfo.push(`My phone: ${responseData.user_phone}`);
    this.d_userContactInfo.push("");
    this.d_userContactInfo.push('Contact an administrator to update email or phone number shown above');
    this.d_userContactInfo.push("");
    const notificationPreferenceDictList = responseData.notification_preferences_dict_list;
    this.d_snsOtpConfirmed = responseData.sns_otp_confirmed;

    notificationPreferenceDictList.forEach(item => {
      const preferenceKey = item.preference_key;

      if (preferenceKey === CsPreferenceKey.OPT_IN_TO_SMS_NOTIFICATIONS) {
        const smsEnabled = item.preference_value;
        this.d_optInToSmsNotifications = smsEnabled;
        this.d_disableSnsNotifications = false;
        return;
      }
      else if (preferenceKey === CsPreferenceKey.OPT_IN_TO_EMAIL_NOTIFICATIONS) {
        const emailEnabled = item.preference_value;
        this.d_optInToEmailNotifications = emailEnabled;
        return;
      }
      else if (preferenceKey === CsPreferenceKey.OPT_IN_TO_MFA_OTP) {
        const mfaOtp = item.preference_value;
        this.d_optInToMfaOtp = mfaOtp;
        return;
      }

      const notificationTypes = item.preference_value;

      if (notificationTypes.includes(CsNotificationMechanism.SMS_NOTIFICATION_TYPE)) {
        enableSmsNotificationDict[preferenceKey] = true;
      }
      else {
        enableSmsNotificationDict[preferenceKey] = false;
      }

      if (notificationTypes.includes(CsNotificationMechanism.EMAIL_NOTIFICATION_TYPE)) {
        enableEmailNotificationDict[preferenceKey] = true;
      }
      else {
        enableEmailNotificationDict[preferenceKey] = false;
      }
      newData.push(item);
    });

    this.d_enableSmsNotificationDict = enableSmsNotificationDict;
    this.d_enableEmailNotificationDict = enableEmailNotificationDict;

    let newFilters = {...this.d_filters}
    let newFilterFields = []

    Object.keys(newData[0]).forEach((obj, id) => {
      newFilters[obj] = { value: null, matchMode: FilterMatchMode.CONTAINS }
      newFilterFields.push(obj)
    })
    this.d_data = newData;
    this.d_filters = newFilters;
    this.d_filterFields = newFilterFields;
  },
  methods: {
    m_cancelOTPDialogCallback() {
      this.d_showOTPDialog = false;
    },
    async m_submitOTPDialogCallback() {
      let params = {sns_otp_value: this.d_snsOtpValue};
      let inputParameters = {params: params};
      let [response, errorMsg] = await g_callPythonApi(CONFIRM_SNS_OTP_VALUE_ENDPOINT, inputParameters);

      if (g_responseHasError(response, errorMsg)) {
        errorMsg = g_getErrorMsg(response, errorMsg)
        g_showErrorToast(errorMsg, this);
        return;
      }

      const successfullyConfirmedSnsOtp = response.data.successfully_confirmed_sns_otp;

      if (successfullyConfirmedSnsOtp) {
        g_showInfoToast("Successfully confirmed One Time Password", this);
        this.d_optInToSmsNotifications = true;
        this.d_disableSnsNotifications = false;
      }
      else {
        const life = 6000;
        const phoneNumber = response.data.phone_number;
        const msg = `Unable to confirm One Time Password to your phone number, which is specified as '${phoneNumber}'. Text notifications will be disabled.`;
        g_showErrorToast(msg, this, life);
        this.d_showOTPDialog = false;
      }
      this.d_showOTPDialog = false;
    },
    async m_requestOTPButtonCallback() {
      let params = {user_password: this.d_passwordForMfaOtp};
      let inputParameters = {params: params};
      const theArgsInput = { endpoint: INITIATE_MFA_OTP_ENDPOINT, inputParameters: inputParameters, method: CsRestMethod.POST }
      let [response, errorMsg] = await g_callPythonApiWithArgs(theArgsInput)

      if (g_responseHasError(response, errorMsg)) {
        errorMsg = g_getErrorMsg(response, errorMsg)
        g_showErrorToast(errorMsg, this);
        return;
      }

      const mfaOtpSetupCode = response.data.mfa_otp_setup_code;
      const userEmail = response.data.user_email;

      if (mfaOtpSetupCode) {
        this.d_mfaOtpSetupKey = mfaOtpSetupCode;
        this.d_mfaOtpImageData = `${PORTAL_PYTHON_URL}/qr_image?setup_key=${mfaOtpSetupCode}&user_email=userEmail`
      }
    },
    async m_optInEmailNotificationCallback() {

      let enableDictEmail = this.d_enableEmailNotificationDict;

      if (!this.d_optInToEmailNotifications) {
        Object.keys(enableDictEmail).forEach((key, id) => {
          enableDictEmail[key] = false;
        });
      }

      this.d_enableEmailNotificationDict = enableDictEmail;

    },
    async m_optInMfaOtpCallback() {

      if (this.d_optInToMfaOtp) {
        this.d_showOTPDialog = true;
      }
    },
    async m_optInSmsNotificationCallback() {
      let enableDictSms = this.d_enableSmsNotificationDict;

      if (!this.d_optInToSmsNotifications) {
        Object.keys(enableDictSms).forEach((key, id) => {
          enableDictSms[key] = false;
        });
      }

      this.d_enableSmsNotificationDict = enableDictSms;

    },
    async p_submitCallback() {

      if (this.p_preSaveNotificationSettingsCallback) {
        this.p_preSaveNotificationSettingsCallback();
      }

      this.d_loadingClass = 'g_c_is_loading'
      await this.m_notificationSettingEnabledCallback();

      setTimeout(() => {
        this.d_loadingClass = "g_c_not_loading";

        if (this.p_closeNotificationDialog) {
          this.p_closeNotificationDialog();
        }

        g_showSuccessToast("Successfully saved settings", this);
      }, 400);
    },
    p_cancelCallback() {
      if (this.p_closeNotificationDialog) {
        this.p_closeNotificationDialog();
      }
    },
    async m_optInToSmsCallback() {
    },
    async m_notificationSettingEnabledCallback() {
      let enableDict = {};

      this.d_data.forEach(item => {
        const preferenceKey = item.preference_key;
        let notificationArray = [];
        const smsEnabled = this.d_enableSmsNotificationDict[preferenceKey];

        if (smsEnabled) {
          notificationArray.push(CsNotificationMechanism.SMS_NOTIFICATION_TYPE);
        }

        const emailEnabled = this.d_enableEmailNotificationDict[preferenceKey];

        if (emailEnabled) {
          notificationArray.push(CsNotificationMechanism.EMAIL_NOTIFICATION_TYPE);
        }

        enableDict[preferenceKey] = notificationArray;
      });

      enableDict[CsPreferenceKey.OPT_IN_TO_SMS_NOTIFICATIONS] = this.d_optInToSmsNotifications;
      enableDict[CsPreferenceKey.OPT_IN_TO_EMAIL_NOTIFICATIONS] = this.d_optInToEmailNotifications;
      enableDict[CsPreferenceKey.OPT_IN_TO_MFA_OTP] = this.d_optInToMfaOtp;
      let params = { notification_settings: JSON.stringify(enableDict) };
      let inputParameters = {params: params};
      const theArgsInput = { endpoint: SET_NOTIFICATION_SETTINGS_ENDPOINT, inputParameters: inputParameters, method: CsRestMethod.POST }
      let [response, errorMsg] = await g_callPythonApiWithArgs(theArgsInput)

      if (g_responseHasError(response, errorMsg)) {
        errorMsg = g_getErrorMsg(response, errorMsg)
        g_showErrorToast(errorMsg, this);
        return;
      }
    },
    m_getCancelLabel() {
      return "Close";
    },
    m_toggleContactDialog() {
      this.d_showUserContactDialog = !this.d_showUserContactDialog;
    }
  },
}
</script>

<style>
@import '../css/cs_globals.css';
</style>

<style scoped> 

.c_info_panel {
  border-radius: 0.5rem;
  border-style: solid;
  border-width: 0.01rem;
  background-color: #f8fafc;
  border-color: #aaa;
  display: grid;
  padding: 1rem;
}
</style>
