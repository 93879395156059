<template>
  <div style="display: grid; justify-content: center; grid-template-columns: 1rem auto; gap: 1.0rem; height: 2rem; width: 37rem;  margin-top: 0.25rem; margin-bottom: 0.25rem">
    <div id="terms_and_conditions_right_id" style="align-self: center">
      <Checkbox id="terms_and_conditions_checkbox_id" 
                cs_id="cs_terms_and_conditions_checkbox_id" 
                @click="m_toggleTermsAndConditionsCheckbox" 
                :binary="true" 
                :modelValue="d_termsAndConditionChecked" />
    </div>
    <div id="terms_and_conditions_left_id">By checking, I agree that I have read, understood and agree to be bound by the 
      <a :href="d_termsAndConditionsUrl" target="_blank" rel="noopener noreferrer">CairnSurgical Terms and Conditions</a></div> 
  </div>
</template>

<script>

import 'primeicons/primeicons.css'
import Checkbox from 'primevue/checkbox';

export default {
  name: "TermsAndConditionsComponent",
  components: {
    Checkbox,
  },
  data: function () {
    return {
      d_termsAndConditionsUrl: null,
      d_termsAndConditionChecked: this.p_initialCheckState,
    }
  },
  props: {
    p_checkboxCallbackFunc: {type: Function, default: null},
    p_termsAndCondtionsRetriever: {type: Function, default: null},
    p_initialCheckState: {type: Boolean, default: false},
  },
  mounted() {
    const retriever = this.p_termsAndCondtionsRetriever;

    if (retriever) {
      const url = this.p_termsAndCondtionsRetriever();
      this.d_termsAndConditionsUrl = url;
    }
  },
  methods: {
    m_toggleTermsAndConditionsCheckbox() {
      this.d_termsAndConditionChecked = !this.d_termsAndConditionChecked;

      if (this.p_checkboxCallbackFunc) {
        this.p_checkboxCallbackFunc(this.d_termsAndConditionChecked);
      }
    }
  }
}
</script>
