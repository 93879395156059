

// TODO: look into this to share constants between backend and frontend
// https://github.com/aantn/reconstant

export const CsOrderTableView = {
    UPCOMING: "Upcoming",
    MY_CURRENT_ACTIVE: "My Active",
    ALL_CURRENT_ACTIVE: "All Active",
    IN_PROCESS: "In Process",
    CLOSED: "Closed",
    MY_CURRENT_ACTIVE_KEY: "MY_CURRENT_ACTIVE",
    ALL_CURRENT_ACTIVE_KEY: "ALL_CURRENT_ACTIVE",
};

Object.freeze(CsOrderTableView);

export const CsStudyType = {
    NOT_SET: "NOT_SET",
    SCREENING_MAMMOGRAM: "SCREENING_MAMMOGRAM",
    DIAGNOSTIC_MAMMOGRAM: "DIAGNOSTIC_MAMMOGRAM",
    LIMITED_ULTRASOUND: "LIMITED_ULTRASOUND",
    BIOPSY: "BIOPSY",
    PRONE_MRI: "PRONE_MRI",
    SUPINE_MRI: "SUPINE_MRI",
};

Object.freeze(CsStudyType);

export const CsTextFieldConstants = {
    TEXT_FIELD_MAX_LENGTH: "75",
};

Object.freeze(CsTextFieldConstants);

export const CsRestMethod = {
    GET: "GET",
    POST: "POST",
    PUT: "PUT",
    DELETE: "DELETE",
};

Object.freeze(CsRestMethod);

export const CsSidebarTab = { 
    APPROVAL_HISTORY_TAB: "APPROVAL_HISTORY_TAB",
    STUDIES_TAB: "STUDIES_TAB",
    GATEWAY_TAB: "GATEWAY_TAB",
    EDIT_ORDER_TAB: "EDIT_ORDER_TAB",
    RADIOLOGIST_NOTE_TAB: "RADIOLOGIST_NOTE_TAB",
    ATTACHMENT_TAB: "ATTACHMENT_TAB",
    AUDIT_LOG_TAB: "AUDIT_LOG_TAB",
    GENERATED_LABEL_TAB: "GENERATED_LABEL_TAB",
}

Object.freeze(CsSidebarTab);

export const CsStageApprovalState = {
    UNASSIGNED_VALUE: "Unassigned",
    IN_PROGRESS_VALUE: "In Progress",
    YES_VALUE: "Yes",
    NO_VALUE: "No",
    DONE_VALUE: "Done",
};

Object.freeze(CsStageApprovalState);


export const CsAcApprovalState = {
    YES_VALUE: "Yes",
    NO_VALUE: "No",
};

Object.freeze(CsAcApprovalState);

export const CsAcApprovalStageLabel = {
    ORDER_CREATE:                     "Order",         
    DICOM_UPLOAD:                     "Uploads",          
    INTAKE_VERIFICATION:              "Intake Verification",           
    RADIOLOGIST_ASSIGNMENT:           "Radiologist Assignment",           
    MRI_SEGMENTATION:                 "MRI Segmentation",           
    // TECHNICIAN_ASSIGNMENT:            "Technician Assignment", // REMOVE_TECHNICIAN_ASSIGNMENT
    BCL_DESIGN:                       "BCL Design",             
    SURGEON_ASSIGNMENT:               "Surgeon Assignment",           
    SURGEON_REVIEW:                   "Surgeon Review",           
    BCL_MANUFACTURING_AND_SHIPMENT:   "Manufacturing",                            
    PAYMENT_PROCESSING:               "Payment Processing",           
    // FINAL_REVIEW:                     "Final Review",
    ORDER_CLOSED:                     "Order Closed",
};

Object.freeze(CsAcApprovalStageLabel);


export const CsPrequisiteErrorType = {
    ORDER_CREATE_CHECKLIST_PREREQ: "ORDER_CREATE_CHECKLIST_PREREQ",
    BCL_DESIGN_PREREQ: "BCL_DESIGN_PREREQ"
};

Object.freeze(CsPrequisiteErrorType);

export const CsNotificationMechanism = {
    SMS_NOTIFICATION_TYPE: "SMS_NOTIFICATION_TYPE",
    EMAIL_NOTIFICATION_TYPE: "EMAIL_NOTIFICATION_TYPE",
}

Object.freeze(CsNotificationMechanism);

export const CsPreferenceKey = {
    NOTIFY_WHEN_ORDER_CREATED: "NOTIFY_WHEN_ORDER_CREATED",
    NOTIFY_WHEN_ORDER_PRE_ASSIGNED_TO_ME: "NOTIFY_WHEN_ORDER_PRE_ASSIGNED_TO_ME",
    NOTIFY_WHEN_ORDER_IS_SUBMITTED_TO_ME: "NOTIFY_WHEN_ORDER_IS_SUBMITTED_TO_ME",
    NOTIFY_WHEN_PROBLEM_IS_REPORTED_TO_ME: "NOTIFY_WHEN_PROBLEM_IS_REPORTED_TO_ME",
    NOTIFY_WHEN_ORDER_IS_CLOSED: "NOTIFY_WHEN_ORDER_IS_CLOSED",
    OPT_IN_TO_SMS_NOTIFICATIONS: "OPT_IN_TO_SMS_NOTIFICATIONS",
    OPT_IN_TO_EMAIL_NOTIFICATIONS: "OPT_IN_TO_EMAIL_NOTIFICATIONS",
    OPT_IN_TO_MFA_OTP: "OPT_IN_TO_MFA_OTP",

    SHOW_INTRO_MESSAGE: "SHOW_INTRO_MESSAGE",
    NUMBER_ORDER_TABLE_ROWS: "NUMBER_ORDER_TABLE_ROWS",
    NUMBER_STUDY_TABLE_ROWS: "NUMBER_STUDY_TABLE_ROWS",
    NUMBER_CROSS_ORDER_AUDIT_TABLE_ROWS: "NUMBER_CROSS_ORDER_AUDIT_TABLE_ROWS",
    NUMBER_SPECIFIC_ORDER_AUDIT_TABLE_ROWS: "NUMBER_SPECIFIC_ORDER_AUDIT_TABLE_ROWS",
    NUMBER_ATTACHMENT_TABLE_ROWS: "NUMBER_ATTACHMENT_TABLE_ROWS",
    NUMBER_APPROVAL_HISTORY_TABLE_ROWS: "NUMBER_APPROVAL_HISTORY_TABLE_ROWS",
    SHOW_SIDEBAR_INFO_PANEL: "SHOW_SIDEBAR_INFO_PANEL",
    NUMBER_SITE_TABLE_ROWS: "NUMBER_SITE_TABLE_ROWS",
    NUMBER_USER_TABLE_ROWS: "NUMBER_USER_TABLE_ROWS",
};

Object.freeze(CsPreferenceKey);

export const CsAcApprovalStage = {
    ORDER_CREATE: "ORDER_CREATE",
    DICOM_UPLOAD: "DICOM_UPLOAD",
    INTAKE_VERIFICATION: "INTAKE_VERIFICATION",
    RADIOLOGIST_ASSIGNMENT: "RADIOLOGIST_ASSIGNMENT",
    MRI_SEGMENTATION: "MRI_SEGMENTATION",
    // TECHNICIAN_ASSIGNMENT: "TECHNICIAN_ASSIGNMENT",  // REMOVE_TECHNICIAN_ASSIGNMENT
    BCL_DESIGN: "BCL_DESIGN",
    SURGEON_ASSIGNMENT: "SURGEON_ASSIGNMENT",
    SURGEON_REVIEW: "SURGEON_REVIEW",
    BCL_MANUFACTURING_AND_SHIPMENT: "BCL_MANUFACTURING_AND_SHIPMENT",
    PAYMENT_PROCESSING: "PAYMENT_PROCESSING",
    // FINAL_REVIEW: "FINAL_REVIEW",
    ORDER_CLOSED: "ORDER_CLOSED",
};

Object.freeze(CsAcApprovalStage);

export const CsAcRole = {
    SUPER_ADMIN: "SUPER_ADMIN",
    ADMIN: "ADMIN",
    INTAKE: "INTAKE",
    TECHNICIAN: "TECHNICIAN",
    RADIOLOGIST: "RADIOLOGIST",
    ENGINEERING: "ENGINEERING",
    ACCOUNTING: "ACCOUNTING",
    MANUFACTURING: "MANUFACTURING",
    SURGEON: "SURGEON",
    HOSPITAL_PHYSICIAN: "HOSPITAL_PHYSICIAN",
    HOSPITAL_STAFF: "HOSPITAL_STAFF",
    HOSPITAL_SUPPORT_STAFF: "HOSPITAL_SUPPORT_STAFF",
    HOSPITAL_BILLING: "HOSPITAL_BILLING",
    OR_STAFF: "OR_STAFF",
}

Object.freeze(CsAcRole);

export const CsAcResource = {
    RADIOLOGIST_NOTES: "RADIOLOGIST_NOTES",
    PHI_PAYLOAD: "PHI_PAYLOAD",
    MANUFACTURING_PAYLOAD: "MANUFACTURING_PAYLOAD",
    MANUFACTURING_LABEL_FILE: "MANUFACTURING_LABEL_FILE",
    VISUALIZER_PAYLOAD: "VISUALIZER_PAYLOAD",
    VISUALIZER_UI_LINK: "VISUALIZER_UI_LINK",
    BCL_PROCESS_INPUTS: "BCL_PROCESS_INPUTS",
    CLINICAL_REPORT: "CLINICAL_REPORT",
    ORDER: "ORDER",
    DICOM_FILES: "DICOM_FILES",
    DICOM_VIEWER_LINKS: "DICOM_VIEWER_LINKS",
    VIEWER_ANNOTATION: "VIEWER_ANNOTATION",
    APPROVAL_HISTORY: "APPROVAL_HISTORY",
    AUDIT_LOG: "AUDIT_LOG",
    SYSTEM: "SYSTEM",
    USER: "USER",
    SITE: "SITE",
    GENERATED_LABELS: "GENERATED_LABELS",
};

Object.freeze(CsAcResource);

export const CsAcAction = {
    UPLOAD: "UPLOAD",
    UPLOAD_TO_AMBRA: "UPLOAD_TO_AMBRA",
    DOWNLOAD: "DOWNLOAD",
    VIEW: "VIEW",
    UPDATE: "UPDATE",
    DELETE: "DELETE",
    APPROVE: "APPROVE",
    CREATE: "CREATE",
    LOGIN: "LOGIN",
    LOGOUT: "LOGOUT",
};

Object.freeze(CsAcAction);

export const CsStageCallbackType = {
    DELEGATE_STAGE: "DELEGATE_STAGE",
    BACKUP_STAGE: "BACKUP_STAGE",
    DONE_WITH_STAGE: "DONE_WITH_STAGE",
};

Object.freeze(CsStageCallbackType);

// at a given stage, need to know whether to delegate to other users at same stage or next stage
// usually, it's next stage, but in the case of UPLOAD_DICOM, delegation goes to same stage
export const CsUserPoolType = {
    CURRENT_STAGE: "CURRENT_STAGE",
    NEXT_STAGE: "NEXT_STAGE",
};

Object.freeze(CsStageCallbackType);

export const TEXT_FIELD_MAX_LENGTH = "75";
export const REFRESH_ORDER_TABLE_CALLBACK = 'orderTableRefreshEvent';
export const CSS_NOT_LOADING_DIRECTIVE = "g_c_not_loading";
export const CSS_LOADING_OVERRIDE_DIRECTIVE = "c_loading_override";

export const FORM_ONE_BUTTON_CSS = "c_one_button";
export const FORM_TWO_BUTTON_CSS = "c_two_button";
export const FORM_THREE_BUTTON_CSS = "c_three_button";

export const NONE_SELECTED = "None Selected";

export const EMPTY_STRING = "";
