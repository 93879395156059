import { createApp } from 'vue'
import App from './App.vue'
import PrimeVue from 'primevue/config';
import ToastService from 'primevue/toastservice';
import ConfirmationService from 'primevue/confirmationservice';
import router from './router';
import vuexStore from './store'
import Tooltip from 'primevue/tooltip';
// import { VueWindowSizePlugin } from 'vue-window-size/option-api';

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from '@fortawesome/free-solid-svg-icons'
library.add(fas);
import { fab } from '@fortawesome/free-brands-svg-icons';
library.add(fab);
import { far } from '@fortawesome/free-regular-svg-icons';
library.add(far);
import { dom } from "@fortawesome/fontawesome-svg-core";
dom.watch();


// import 'primevue/resources/themes/nova/theme.css' // USED THIS ONE FROM AUG 23, 2021 TO Jan17, 2022
import 'primevue/resources/themes/mdc-light-indigo/theme.css' // ok, but no border on table TOM LIKES THIS


// import 'primevue/resources/themes/rhea/theme.css' /// this one looks nice
// import 'primevue/resources/themes/prestige/theme-bluegray.css' /// this one looks nice

// import 'primevue/resources/themes/saga-blue/theme.css' // too light
// import 'primevue/resources/themes/arya-blue/theme.css' // nope, black background
// import 'primevue/resources/themes/luna-blue/theme.css' // nope, black background
// import 'primevue/resources/themes/bootstrap4-light-blue/theme.css' // no
// import 'primevue/resources/themes/bootstrap4-dark-blue/theme.css' // no
// import 'primevue/resources/themes/md-dark-indigo/theme.css' // no
// import 'primevue/resources/themes/md-light-indigo/theme.css' // no
//import 'primevue/resources/themes/md-light-indigo/theme.css' // no
// import 'primevue/resources/themes/vela-blue/theme.css' // no

// import VueSweetalert2 from 'vue-sweetalert2';
// import 'sweetalert2/dist/sweetalert2.min.css';


import 'primeflex/primeflex.css'
// import 'primevue/resources/themes/nova-alt/theme.css'        // this one looks nice
import 'primevue/resources/primevue.min.css'                 //core css
import 'primeicons/primeicons.css'                           //icons
import BadgeDirective from 'primevue/badgedirective'

window.name = "cairn_portal";
// const router = new VueRouter({
//     // routes // short for `routes: routes`
//   })
// const vuexStore = new Vuex.Store({
//     state: {
//       count: 0
//     },
//     mutations: {
//       increment (state) {
//         state.count++
//       }
//     }
//   })
// import VueFileAgent from 'vue-file-agent';
// import VueFileAgentStyles from 'vue-file-agent/dist/vue-file-agent.css';

// import Toast from "vue-toastification";
// import "vue-toastification/dist/index.css";



export const g_app = createApp(App);

export default g_app;

// app.use(VueFileAgent);

// g_app.use(VueSweetalert2);

g_app.use(ConfirmationService);
g_app.use(ToastService);
// g_app.use(Toast);
g_app.use(PrimeVue);
g_app.use(router);
g_app.use(vuexStore);
// g_app.use(VueWindowSizePlugin);
g_app.directive('tooltip', Tooltip);
g_app.directive('badge', BadgeDirective);
g_app.component("font-awesome-icon", FontAwesomeIcon)


g_app.mount('#app')
