<template>
<BlockUI :blocked="d_isNotificationSettingsSaving" :baseZIndex="999999" :fullScreen="true">
<div class="cs_navbar">
    <Toast class="g_c_toast" :autoZIndex="true" position="top-center" site="main" />
    <Dialog header="Select Language" v-model:visible="d_showLanguageDialog" :style="{width: '12 rem'}" :maximizable="true" :modal="true">
      <div style="padding-top: 1rem; display:grid; grid-display-rows: 2rem 2rem 2rem; grid-gap: 0.8rem; justify-content: center" >
        <Button class="g_cs_button_fix  p-button-rounded" style="width: 7rem" @click="m_changeLanguageGerman">Deutsch</Button>
        <Button class="g_cs_button_fix  p-button-rounded" style="width: 7rem" @click="m_changeLanguageEnglish">English</Button>
        <Button class="g_cs_button_fix  p-button-rounded" style="width: 7rem" @click="m_changeLanguageSpanish">Español</Button>
        <Button class="g_cs_button_fix  p-button-rounded" style="width: 7rem" @click="m_changeLanguageFrench">Français</Button>
        <Button class="g_cs_button_fix  p-button-rounded" style="width: 7rem" @click="m_changeLanguageGreek">Ελληνικά </Button>
        <Button class="g_cs_button_fix  p-button-rounded" style="width: 7rem" @click="m_changeLanguageItalian">Italiano</Button>
      </div>
    </Dialog>

    <ConfirmationDialogComponent v-if="d_showHelpDialog"
      :p_confirmationMessageArray="d_placeHolder"
      p_dialogHeader="CairnSurgical Portal Help"
      :p_cancelCallback="m_cancelHelpDialog"
    />
    <OverlayPanel ref="user_overlay_ref" >
      <!-- make repeat have 4 instead of 3 for show dialog -->
      <div style="width: 12rem; display: grid; grid-template-rows: repeat(3, 1fr); justify-items: start">
        <Button style="width: auto" class="p-button-secondary p-button-text" @click="m_changePasswordCallback"     icon="pi pi-lock"        label="Change Password"/>
        <Button style="width: auto" class="p-button-secondary p-button-text" @click="m_showAboutCallback"          icon="fa fa-info-circle" label="About"/>
        <Button style="width: auto" class="p-button-secondary p-button-text" @click="m_showSettingsDialogCallback" icon="pi pi-cog" label="My Settings"/>
        <!-- <Button style="width: auto" class="p-button-secondary p-button-text" v-if="d_showLanguageOptionsDialogButton" @click="m_showLanguageDialog"         icon="pi pi-comment"     label="Select Language" /> -->
        <Button style="width: auto" class="p-button-secondary p-button-text"  @click="m_showHelpDialog"          icon="pi pi-question-circle"    label="Help" />
        <Button style="width: auto" class="p-button-secondary p-button-text" @click="m_logoutCallback"             icon="pi pi-sign-out"    label="Logout" />
      </div>
    </OverlayPanel>
    <div class="cs_navbar_top_area" v-if="this.$store.state.s_userName">
          <TabMenu class="cs_tab_menu" :model="items" :activeIndex="d_activeIndex"/>
        <Button @click="m_showHelpDialog" style="margin-left: 1rem; justify-self: center; align-self: center; " class="p-button p-component p-button-icon-only cs_button p-button-rounded p-button-outlined p-button-plain" icon="pi pi-question"></Button>


        <Image :style="m_getLogoStyle()" style="padding-left: 1.5rem;" class="cs_image" :src="d_imagePath"></Image>
        <Chip style="border-color: rgba(122, 122, 122, 0.34) !important; border-style: solid; border-width: 1px; border-radius: 1.5rem" :class="d_chipClass" v-model:label="c_globalUserName" @click="m_showOverlay" icon="pi pi-user">
        </Chip>
    </div>
    <ConfirmationDialogComponent v-if="d_showAboutDialog"
      :p_confirmationMessageArray="d_aboutVersionMessageArray"
      p_dialogHeader="About CairnSurgical Portal"
      :p_cancelCallback="m_cancelAboutDialog"
    />
    <Dialog id="settings_dialog_id" header="Settings" style="width: 45.5rem" v-model:visible="d_showSettingsDialog" :modal="true">
      <NotificationSettingsComponent :p_closeNotificationDialog="m_closeNotificationDialog"
                                     :p_preSaveNotificationSettingsCallback="m_preSaveNotificationSettingsCallback"
      />
    </Dialog>
    <ChangePasswordComponent :p_showChangePasswordDialog="d_showChangePassword"
                             :p_closable="true"
                             :p_cancelCallback="m_cancelPasswordChangeCallback"
                             :p_submitChangedPasswordCallback="m_submitChangedPasswordCallback" />

</div>
</BlockUI>
</template>

<script>
import {CsRestMethod} from '../utils/cs_constants.js'
import OverlayPanel from 'primevue/overlaypanel'
import Dialog from 'primevue/dialog'
import Button from 'primevue/button'
import Image from 'primevue/image'
import Chip from 'primevue/chip'
import Toast from 'primevue/toast'
import Menubar from 'primevue/menubar'
import TabMenu from 'primevue/tabmenu'
import ConfirmationDialogComponent from "@/components/ConfirmationDialogComponent";
import ChangePasswordComponent from './ChangePasswordComponent'
import g_callPythonApi, {g_responseHasError, g_getErrorMsg, g_showErrorToast, g_showSuccessToast} from '../utils/rest_manager.js'
import { PORTAL_PYTHON_URL } from '../utils/cs_globals.js'
import {g_changePassword} from '../utils/rest_manager.js'
import NotificationSettingsComponent from "@/components/NotificationSettingsComponent";
import BlockUI from "primevue/blockui";

const LOGOUT_ENDPOINT = 'logout'
const NAVBAR_CONFIG_ENDPOINT = 'navbar_config'
const TAB_CONFIG_INFO_ENDPOINT = 'tab_config_info'
import {PRODUCTION_MODE} from '../utils/cs_globals.js'


/**
 * The component that spans the top of the app, including the user button.
 * @component
 */
export default {
  name: "NavbarComponent",
  components: {
    BlockUI,
    NotificationSettingsComponent,
    ChangePasswordComponent,
    ConfirmationDialogComponent,
    Button,
    Image,
    Dialog,
    Chip,
    Menubar,
    OverlayPanel,
    Toast,
    TabMenu,
  },
  data() {
    return {
      d_activeIndex: 0,
      d_chipClass: null,
      d_imagePath: `${PORTAL_PYTHON_URL}/logo`,
      items: [
      ],
      d_showUserTab: !PRODUCTION_MODE,
      d_initialItems: [],
      d_showLanguageDialog: false,
      d_showChangePassword: false,
      d_showSettingsDialog : false,
      d_showAboutDialog: false,
      d_aboutVersionMessageArray: null,
      d_isNotificationSettingsSaving: false,
      d_isSaving: false,
      d_showLanguageOptionsDialogButton: true,
      d_placeHolder: ["THIS IS A PLACEHOLDER"],
      d_showHelpDialog: false
    }
  },
  async created() {

    let [response, errorMsg] = await g_callPythonApi(NAVBAR_CONFIG_ENDPOINT);

    if (g_responseHasError(response, errorMsg)) {
      errorMsg = g_getErrorMsg(response, errorMsg)
      g_showErrorToast(errorMsg, this);
      return
    }

    this.d_chipClass = response.data.chip_class;
  },
  async mounted() {

    const params = [] 

    let inputParameters = {params: params};
    let [response0, errorMsg0] = await g_callPythonApi(TAB_CONFIG_INFO_ENDPOINT, inputParameters);

    if (g_responseHasError(response0, errorMsg0)) {
      errorMsg0 = g_getErrorMsg(response0, errorMsg0)
      g_showErrorToast(errorMsg0);
      return
    }

    this.d_initialItems = response0.data.config_info_list;

    const aboutVersionUnparsed = response0.data.about_version;
    let aboutVersionArray = [];

    if (response0.data.about_version) {
      this.d_aboutVersionMessageArray = response0.data.about_version.split("|");
    }

    const initialItems = this.d_initialItems;
    this.items = [...this.d_initialItems];
    this.d_activeIndex = 0
  },
  methods: {
    m_preSaveNotificationSettingsCallback() {
      this.d_isNotificationSettingsSaving = true;
    },
    m_closeNotificationDialog() {
      this.d_isNotificationSettingsSaving = false;
      this.d_showSettingsDialog = false;
    },
    m_getLogoStyle() {
      if (PRODUCTION_MODE) {
        return {};
      }
      else {
        return { "cursor": "pointer" };
      }
    },
    m_cancelPasswordChangeCallback() {
      this.d_showChangePassword = false;
    },
    async m_submitChangedPasswordCallback(oldPassword, newPassword) {
      let success = await g_changePassword(this.c_globalUserName, oldPassword, newPassword);

      if (success) {
        this.d_showChangePassword = false;
        g_showSuccessToast("Successfully changed password", this);
        return success;
      }
      else {
        return success;
      }
    },
    m_getCurrentLanguage(locale) {
      if (locale === 'es') {
        return "Español";
      }
      else if (locale === 'en') {
        return "English";
      }
      else if (locale === 'de') {
        return "Deutsch";
      }
      else if (locale === 'el') {
        return "Ελληνικά";
      }
      else if (locale === 'fr') {
        return "Français";
      }
      else if (locale === 'it') {
        return "Italiano";
      }
    },
    m_changeLanguageEnglish() {
      this.$store.commit('changeGlobalStateLocale', 'en');
      window.location.reload();
      // this.$router.replace('/orders');
    },
    m_changeLanguageGreek() {
      this.$store.commit("changeGlobalStateLocale", "el");

      window.location.reload();
    },
    m_changeLanguageFrench() {
      this.$store.commit("changeGlobalStateLocale", "fr");

      window.location.reload();
    },
    m_changeLanguageGerman() {
      this.$store.commit("changeGlobalStateLocale", "de");
      window.location.reload();
    },
    m_changeLanguageItalian() {
      this.$store.commit("changeGlobalStateLocale", "it");
      window.location.reload();
    },
    m_changeLanguageSpanish() {
      this.$store.commit("changeGlobalStateLocale", "es");
      window.location.reload();
    },
    async m_handleRoleBasedRendering() {
      this.items = [...this.d_initialItems];
    },
    makeActive(item) {
      this.d_activeIndex = item
    },
    m_showSettingsDialogCallback() {
      this.d_showSettingsDialog = true;
    },
    m_showAboutCallback() {
      this.d_showAboutDialog = true;
    },
    m_cancelAboutDialog() {
      this.d_showAboutDialog = false;
    },
    m_changePasswordCallback() {
      this.d_showChangePassword = true;
    },
    m_showLanguageDialog() {
      this.d_showLanguageDialog = true;
    },
    m_showHelpDialog() {
      // this.d_showHelpDialog = true;
      window.open("https://cairn-st-p-info.s3.eu-north-1.amazonaws.com/ROOT/doc/portal_documentation.html");
    },
    m_cancelHelpDialog() {
      this.d_showHelpDialog = false;
    },
    async m_logoutCallback(event) {
      // console.log("In logoutCallback")
      if (event) {
        this.$refs.user_overlay_ref.toggle(event);
      }

      this.$store.commit('changeGlobalStateUserName', null)
      this.$store.commit('changeGlobalStateLocale', null)
      this.$store.commit('changeGlobalStateCurrentOrderId', null)

      let [response, errorMsg] = await g_callPythonApi(LOGOUT_ENDPOINT)

      if (g_responseHasError(response, errorMsg)) {
        errorMsg = g_getErrorMsg(response, errorMsg)
        g_showErrorToast(errorMsg, this);
        return
      }

      await this.$router.push("/")

      // window.location.reload();
      window.location.href = '/';

    },
    m_showOverlay(event) {
      this.$refs.user_overlay_ref.toggle(event)
    }
  },
  computed: {
    c_globalUserName() {
      return this.$store.state.s_userName
    }
  }
}

</script>
<style>
.g_c_toast :deep(.p-toast-message-content) {
  background-color: pink !important;
  margin-left: 10rem !important;
}
</style>

<style scoped> 

.cs_navbar_top_area {
  display: grid;
  grid-template-columns: 3.5fr .5fr .7fr .6fr
}

.cs_chip_class {
    cursor: pointer;
}

.cs_role_base {
    background-color: lightgray;
}

.cs_role_accounting {
  background-color: yellow;
}

.cs_role_hospital_physician {
  color: black;
  background-color: lightyellow;
}

.cs_role_technician {
    background-color: steelblue;
}

.cs_role_radiologist {
    background-color: forestgreen;
}

.cs_role_hospital_staff {
    color: black;
    background-color: greenyellow;
}

.cs_role_hospital_support_staff {
    background-color: seagreen;
}

.cs_role_manufacturing {
    background-color: lightskyblue;
}

.cs_role_surgeon {
    background-color: lightblue;
}

.cs_role_intake {
    background-color: cadetblue;
    color: white;
}

.cs_role_engineer {
    background-color: lightcyan;
}

.cs_chip_class :deep(.p-chip-icon) {
    margin-left: 0.8rem;
}

.cs_chip_class :deep(.p-chip-text) {
    /* DAX: WORKS FOR FIREFOX, NOT CHROME
  margin-top: 0.3rem !important;
  */
  margin-bottom: 0.2rem !important;
  align-items: center;
}

.top-area :deep(.p-menuitem-text) {
    /* DAX: WORKS FOR FIREFOX, NOT CHROME
  padding-top: .33rem !important;
  */
}

.cs_white_text {
  color: white !important;
}

.cs_image :deep(img) {
  height: 2.5rem;
}

.c_table_buttons {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 0.5rem;
}

.cs_tab_menu {
  overflow-x: visible !important;
  display: auto !important;
}

.p-tabmenu-nav a {
  height: 5rem !important;
}
</style>