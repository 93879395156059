<template>

  <div id="app_id">
    <head>
      <meta charset="utf-8">
      <link rel="shortcut icon" type="image/png" href="/static/favicon.ico"/>
      <!-- <meta name="csrf-token" content="{{ csrf_token() }}"> -->

    </head>

    <div class="c_app_top_area" v-if="this.$store.state.s_userName">
      <NavbarComponent/>
    </div>
    <!-- <div class="c_app_top_area" v-else>
      <Login />
    </div> -->
    <router-view/>
  </div>
</template>

<script>


import Login from './components/LoginComponent'
import NavbarComponent from './components/NavbarComponent'
import Toast from 'primevue/toast'

 /**
   * The main App vue file
   */
export default {
  name: 'App',
  components: {
    NavbarComponent,
    Login,
    Toast
  },
  mounted() {
  }
}
</script>


<style>
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
  color: #1a73e8 !important;
}

#visualizer_dialog_button_id {
  background-color: #1a73e8 !important;
}

.p-button.p-button-secondary.p-button-text, .p-buttonset.p-button-secondary > .p-button.p-button-text, .p-splitbutton.p-button-secondary > .p-button.p-button-text {
  color: #1a73e8 !important;
}

.p-fileupload .p-button {
  background-color: #1a73e8 !important;
}

.p-dialog .p-dialog-header {
  border-bottom: #ddd 1px solid !important;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenu-ink-bar {
  background-color: #1a73e8 !important;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  padding-bottom: 0.3rem !important;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  color: #1a73e8 !important;
}

.p-tabview .p-tabview-nav .p-tabview-ink-bar {
  background-color: #1a73e8 !important;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  color: #1a73e8 !important;
  background: rgba(26, 115, 232, 0.12) !important;
}

.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(2n) {
  background:  #f4f4f4 !important;
}

.p-component {
  font-family: Arial, sans-serif !important;
}

html, body {
  font-family: Arial, sans-serif !important;
}
#app_id {
  /*font-family: Avenir, Helvetica, Arial, sans-serif;*/
  font-family: Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*text-align: center;*/
  /*
  background-color: black;
  height: 99vh;
  */
}

</style>
